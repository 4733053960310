<template>
    <base-page>
        <el-card style="margin: 5px 0px 5px 0px">
            <el-collapse accordion style="border: none">
                <el-collapse-item name="1" style="border: none">
                    <template slot="title">
                        <div slot="header" class="clearfix">
                            <titlu-pagina Titlu="Stoc marfă niruri" @on_add_clicked="show_dialog()"
                                :AdaugaVisible="false" />
                        </div>
                    </template>
                    <div class="filtre">
                        <el-form @submit="refresh_info()" label-position="top">

                            <el-row :gutter="20">

                                <el-col style="width: 20%">
                                    <el-form-item label="Furnizor">
                                        <el-select class="full-width" v-model="Filters.Furnizor" filterable>
                                            <el-option v-for="item in Info.furnizori" :key="item.Id" :label="item.Nume"
                                                :value="item.Id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col style="width:20%">
                                    <el-form-item class="full-width" label="Interval data document">
                                        <el-date-picker class="full-width" v-model="Filters.Data" type="daterange"
                                            range-separator="-" start-placeholder="Data inceput"
                                            end-placeholder="Data sfarsit" :picker-options="datePickerOptions"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>

                            </el-row>

                            <el-col>
                                <div class="footer-form">
                                    <el-button type="primary" native-type="submit" @click="refresh_info()">Aplica
                                    </el-button>
                                    <el-button type="danger" @click="reset()">Reseteaza</el-button>
                                    <el-button type="info" native-type="submit" @click="print()">Printeaza</el-button>
                                </div>
                            </el-col>

                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

        <hr>

        <template v-for="item in Results">
            <br>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <p><b>Stoc comanda: {{item.NumarDocument}}</b></p>
                    </div>
                    <div class="col">
                        <p>Curs: {{item.Curs}}</p>
                    </div>
                    <div class="col">
                        Data Curs: {{item.DataCurs}}
                    </div>
                    <div class="col">
                        Data document: {{item.DataDocument}}
                    </div>
                    <div class="col">
                        Data intrare: {{item.DataIntrare}}
                    </div>
                </div>
            </div>

            <el-table :header-cell-style="{ background: '#393E46' }" :data="item.produse">
                <el-table-column prop="CodProdus" label="Cod"></el-table-column>
                <el-table-column prop="NumeProdus" label="Denumire"></el-table-column>
                <el-table-column prop="Denumire" label="Amb."></el-table-column>
                <el-table-column prop="stoc" label="Stoc"></el-table-column>
                <el-table-column prop="PUEuro" label="PU (€)"></el-table-column>
                <el-table-column prop="TotalEuro" label="TOTAL (€)"></el-table-column>
                <el-table-column prop="PretFaraTVA" label="PU (Lei)"></el-table-column>
                <el-table-column prop="TotalLei" label="TOTAL (Lei)"></el-table-column>
            </el-table>

            <div class="container">
                <br>
                <div class="row">
                    <div class="col-2">
                        <p>Cost manipulare: {{item.CostManipulare}}</p>
                    </div>
                    <div class="col-sm">
                        <p>Cost transport: {{item.CostTransport}}</p>
                    </div>
                </div>

            </div>

            <hr>
        </template>

    </base-page>
</template>
  
<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from "@/pages/clienti/Clienti_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
    name: 'app',
    extends: BasePage,
    components: {
        "base-page": BasePage,
        "Clienti-dialog": Clienti_dialog,
        "titlu-pagina": TitluPagina,
    },
    data() {
        return {
            Results: [],
            Filters: {
                Furnizor: '',
                Data: [],
                Nume: 'all data',
            },
            Info: {
                furnizori: [],
            },
            datePickerOptions: { firstDayOfWeek: 1 },
            printBTN: true,
        }
    },
    methods: {
        async get_info() {
            var response = await this.post("Rapoarte/Stoc_marfa_niruri/get_info_niruri", { Filters: this.Filters });
            this.Results = response.niruri;
            this.Info.furnizori = response.furnizori;

            console.log(response);
        },
        async refresh_info() {
            var response = await this.post("Rapoarte/Stoc_marfa_niruri/get_info_niruri", { Filters: this.Filters });
            this.Results = response.niruri;

            if(this.Filters.Furnizor){
                this.Filters.Nume = this.Info.furnizori[this.Filters.Furnizor-1]['Nume'];
                console.log(this.Filters.Nume);
            }
        },
        async reset() {
            this.Filters.Furnizor = '';
            this.Filters.Data = [];

            var response = await this.post("Rapoarte/Stoc_marfa_niruri/get_info_niruri", { Filters: this.Filters });
            this.Results = response.niruri;
        },

        async print() {
            let res = await this.post('Rapoarte/Stoc_marfa_niruri/print_stoc_niruri', { Results: this.Results, Filters: this.Filters});

            console.log(this.Results);  
            let doc = window.open("", "_blank");    
            doc.document.write(res.html);
        }

    },
    mounted: function () {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
}
</script>

  
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.block-content {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}

.block-text {
    padding: 15px;
    font-size: 30px;
    font-weight: 700;
}

.fade-animation {
    -webkit-animation-name: fade-in-up;
    animation-name: fade-in-up;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fade-in-up {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
</style>

<style lang="less" scoped>
.filtre {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(2);

    .el-col {
        padding: 0.25em;
    }

    .el-input-number {
        width: 100% !important;
    }

    .tabel-data {
        width: 300px !important;
        word-break: keep-all;

    }

}
</style>